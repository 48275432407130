import React from "react";

const QandA = (props) => {
    return (
        <div>
            <h2 style={{fontSize: "3vw", textAlign: "center", color: "gold", WebkitTextStroke: "0.4vw black", paintOrder: "stroke fill", paddingTop: "2vw"}}>
                {props.question}
            </h2>
            <h3 style={{textAlign: "center", fontSize: "2.5vw"}}>
                {props.answer}
            </h3>
        </div>
    )
}

export default QandA;
