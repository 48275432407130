
import React from "react";
import { Nav, NavLink, NavMenu } from "./NavbarElements";

const Navbar = () => {
    const links =[{address : "/", name : "Home "}, 
                  {address : "/FAQ", name : "FAQ "}, 
                  {address : "/auditions", name : "Audition"}, 
                  {address : "/contact", name : "Contact Us"},
                  {address : "/members", name : "Our Team"},
                  {address : "/alumni", name : "Alumni "}
                 ]
	return (
		<> 
			<Nav>
				<NavMenu>
                    {links.map(link => (
                        <NavLink to={link.address} activeStyle style={{  WebkitTextStroke: "0.1vw black",  paintOrder: "stroke fill"}}> {link.name} </NavLink>
                    ))}
				</NavMenu>
			</Nav>
		</>
	);
};

export default Navbar;
