import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from './CarouselItem';
import ShortForm from "../images/Shortform.png";
import Longform from "../images/Longform.png";

// Need 3 components (really one that takes in an image for a background)
const style = {
  text: {
    fontSize: "3vw",
    fontWeight: "bold",
    color: 'white',
    top: "5%",
    left: "10%",
    right: "10%",
    position: "absolute",
    WebkitTextStroke: "0.3vw black",
    paintOrder: "stroke fill"
  }
}

function ImprovFormsCarousel() {
  return (
      <Carousel>
        <Carousel.Item>
          <CarouselItem image={ShortForm} description="A Group Picture of the Smile and Nod Improv team's Musical Show" captionStyle={style.text}/>
        </Carousel.Item>
        <Carousel.Item>
          <CarouselItem image={Longform} description="A Group Picture of the Smile and Nod Improv team's D&D Show" captionStyle={style.text}/>
        </Carousel.Item>
      </Carousel>
  );
}

export default ImprovFormsCarousel;