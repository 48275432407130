import React from "react";
import "./InstagramButton.css";

const InstagramButton = ({height}) => {
    return(
    <a href="https://www.instagram.com/smileandnodcp?igsh=MzRlODBiNWFlZA==" className="box" style={{height: height, paddingLeft: 20, paddingRight: 20}}>
        <h2 style={{textAlign: "center", color: "#fccc63", fontSize: "3vw", WebkitTextStroke: "0.2vw #000", paintOrder: "stroke fill"}} >@smileandnodcp on IG</h2>
    </a>
    )
}

export default InstagramButton;