// This page displays the current members of the team

import React from "react";
import Footnote from "../components/Footnote";
import MemberGrid from "../components/MemberGrid";
import "./auditionStyles.css";

const Members = () => {
	return (
		<div style={{marginTop: "0.5vw"}}>
			<h1 className="mainHeader">
                Current Members
            </h1>
            <MemberGrid/>
			<Footnote/>
		</div>
	);
};

export default Members;
