import React from "react";

const styles = {
    placement : {
        position: "relative",
        paddingTop: 10,
        paddingBottom: 10,
        left: 0,
        bottom: 10,
        right: 0
    },
    textHandler : {
        margin: "auto",
        textAlign: 'center',
        width: "80%",
        fontSize: "1.5vw"
    }
}

const Footnote = () => {
    return (
        <div style={styles.placement}>
            <hr></hr>
            <p style={styles.textHandler}> Smile and Nod is committed to constantly learning about and encouraging diversity of all kinds. We accept all, regardless of age, gender, gender identity or expression, sexuality, race, color, religion, national origin, disability or ethnicity. We recognize that the key to a better future is one where all voices are heard, respected, and appreciated. Our key to comedy is equity and inclusion. Sincerely, Smile and Nod.
*Smile and Nod stands in support and solidarity of BLM and BIPOC.</p>
        </div>
    )
}

export default Footnote;