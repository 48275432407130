// Filename - ./components/Navbar.js

import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
	background: #2A4F31;
	position: sticky;
	top: 0px;
	height: 5vw;
	display: flex;
	z-index: 12;
`;

export const NavLink = styled(Link)`
	color: #FCCA46;
	display: flex;
	align-items: center;
	font-size: 2vw;
	text-decoration: none;
	padding: 0 3vw;
	height: 100%;
	cursor: pointer;
	&.active {
		color: #FFFFFF;
	}

`;

export const Bars = styled(FaBars)`
	display: none;
	color: #808080;
	@media screen and (max-width: 768px) {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 75%);
		font-size: 1.8rem;
		cursor: pointer;
	}
`;

export const NavMenu = styled.div`
	display: flex;
	margin: auto;
	align-items: center;
	/* width: 100vw;
white-space: nowrap; */
`;
