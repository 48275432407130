// Filename - pages/FAQ.js

import React from "react";
import Footnote from "../components/Footnote";
import QandA from "../components/QandA";
import "./auditionStyles.css";

const questions = [
	{question: "Where is Philips Hall?", answer: "Like all good treasures, our comedy gold can seem a little hidden! Philips Hall (PAC 124) is located behind the Performing Arts Center, right next to the street."},
	{question : "Does Smile and Nod Do Paid Gigs?", answer : "Yes! Smile and Nod loves to perform for our community and that includes for local businesses. Please reach out to our email with the dates that would work for your business and we will do our best to arrange a show for you! Although we'd love to be able to post times we are able to perform, our non-Saturday show availability changes quarterly based on the schedules of our team members."}, 
	{question: "How Can I Join The Team?", answer: "We typically hold auditions on the first Friday of every Cal Poly quarter! We are looking for a wide variety of comedy styles and talents, so come show us what you got!"},
	{question : "If I Didn't Make It On Team After Auditioning, What Do I Do?", answer : "Audition again! We hold auditions every quarter and only take a select few each time. Some quarters we are trying to fill certain gaps on team, so you might have just been unlucky. It's completely normal for team members to audition multiple times before making it on team. If you want to practice, go to our public Wednesday workshops where we teach and practice improv weekly!"}, 
  ]

const FAQ = () => {
	return (
		<div>
			<div style={{margin: "auto", width:"70%"}}>
				<h1 style={{marginTop: "0.5vw"}} className="mainHeader">
					Frequently Asked Questions
				</h1>
				{questions.map(Q => 
					<QandA question={Q.question} answer={Q.answer} />
					)}
			</div>
			<Footnote/>
		</div>
		
	);
};

export default FAQ;
