import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from './CarouselItem';
import DandD from "../images/audience.jpg";
import Pirate from "../images/pirate.jpeg";
import Musical from "../images/Musical.jpeg";

// Need 3 components (really one that takes in an image for a background)
const style = {
  text: {
    fontSize: "3vw",
    fontWeight: "bold",
    color: 'white',
    top: "5%",
    left: "10%",
    right: "10%",
    position: "absolute",
    WebkitTextStroke: "0.3vw black",
    paintOrder: "stroke fill"
  }
}

function PreviewCarousel() {
  return (
      <Carousel>
        <Carousel.Item>
          <CarouselItem image={Musical} description="A Group Picture of the Smile and Nod Improv team's Musical Show" caption="Never Before Seen" captionStyle={style.text}/>
        </Carousel.Item>
        <Carousel.Item>
          <CarouselItem image={DandD} description="A Group Picture of the Smile and Nod Improv team's D&D Show" caption="Never Seen Again"  captionStyle={style.text}/>
        </Carousel.Item>
        <Carousel.Item>
          <CarouselItem image={Pirate} description="A Group Picture of the Smile and Nod Improv team's Pirate Show" caption="Never Fails To Make You Laugh" captionStyle={style.text}/>
        </Carousel.Item>
      </Carousel>
  );
}

export default PreviewCarousel;