import React from "react";

const ImageBox = ({height, width, img, alt}) => {
    return(
    <div>
        <img style={{height: height, width: width}} src={img} alt={alt}/>
    </div>
    )
}

export default ImageBox;