import React from "react";
import 'bootstrap/dist/css/bootstrap.css';

const CarouselItem = (props) => {
    return (
        <div style={{position: "relative"}}>
            <img className="d-block w-100" src={props.image} alt={props.description} style={{height: "100%", width: "100%"}}/>
            <h1 style={props.captionStyle}>{props.caption}</h1>
        </div>
    );
}

export default CarouselItem;