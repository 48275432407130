// Our Contact Us Page

import React from "react";
import Footnote from "../components/Footnote";
import "./contact.css";


const Contact = () => {
	return (
		<div>
			<div style={{marginTop: "0.5vw", marginLeft: "5%", marginRight: "5%", textAlign: "justified"}} className="contentContainer">
				<h1 className="mainHeader">
					Contact Us
				</h1>
				<h2 style={{textAlign: "center", fontSize: "2.5vw"}}>
					For all business inquiries, please reach out to <a className="link email" href="mailto:smileandnodcalpoly@gmail.com">smileandnodcalpoly@gmail.com</a>.
				</h2>
				<h2 style={{textAlign: "center", fontSize: "2.5vw"}}>
					For information on upcoming shows and events, follow our <a className="link Instagram" href="https://www.instagram.com/smileandnodcp?igsh=MzRlODBiNWFlZA==">Instagram</a>.
				</h2>
				<h2 style={{textAlign: "center", fontSize: "2.5vw"}}>
					For information on workshops and auditions, join our <a className="link GroupMe" href="https://groupme.com/join_group/63039027/PT35qZCs">Workshop GroupMe</a>.
				</h2>
			</div>
			<div className="footer">
				<Footnote/>
			</div>
		</div>	
		
	);
};

export default Contact;
