import React from "react";

const Member = ({first_name, middle_name, last_name, img}) => {
    const alt = "Photo of "+{first_name}+" "+{last_name}
    return(
    <div style={{display: "flex", flexDirection: "column", textAlign: "center", flexBasis: "33%", flex: 1}}>
        <div>
            <img src={img} alt={alt} style={{height: "16vw", width: "12vw"}}/>
        </div>
        <div>
        <p style={{marginTop: "0.4vw", fontSize: "1.2vw"}}>{first_name} "{middle_name}" {last_name}</p>
        </div>
    </div>
    )
}

export default Member;