import React from "react";
import pictureFrame from "../images/Framed.png"

const localstyle = {
    picture : {
        height: "24vw",
        width: "18vw"
    }
}

const CouldBeYou = () => {
    return (
        <div>
            <img style={localstyle.picture} src={pictureFrame} alt="Frame"/>
        </div>
    )
    
}

export default CouldBeYou;